import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PublicProject } from '../../../types/API/PublicProjects';

interface PublicProjectsState {
  publicProjects: PublicProject[];
  filteredPublicProjects: PublicProject[];
  projectToEditOrDelete?: PublicProject;
  currentPage: number;
  perPage: number;
  totalPages: number;
}

const initialState: PublicProjectsState = {
  publicProjects: [],
  filteredPublicProjects: [],
  projectToEditOrDelete: undefined,
  currentPage: 1,
  perPage: 10,
  totalPages: 0
};

export const publicProjectsSlice = createSlice({
  name: 'publicProjects',
  initialState,
  reducers: {
    setPublicProjects: (state, action: PayloadAction<PublicProject[]>) => {
      state.publicProjects = action.payload;
    },
    setFilteredPublicProjects: (state, action: PayloadAction<PublicProject[]>) => {
      state.filteredPublicProjects = action.payload;
    },
    setProjectToEditOrDelete: (state, action: PayloadAction<PublicProject | undefined>) => {
      state.projectToEditOrDelete = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    resetPublicProjectsState: (state) => {
      state.publicProjects = [];
      state.filteredPublicProjects = [];
      state.projectToEditOrDelete = undefined;
      state.currentPage = 1;
      state.perPage = 10;
      state.totalPages = 0;
    }
  }
});

export default publicProjectsSlice.reducer;
export const {
  setProjectToEditOrDelete,
  setPublicProjects,
  resetPublicProjectsState,
  setFilteredPublicProjects,
  setCurrentPage,
  setPerPage,
  setTotalPages
} = publicProjectsSlice.actions;
