import { useMemo } from 'react';
import { IBuffer } from '../../../../types/API/Region';
import { PropertySection } from '../components/PropertySection';
import { usePublicProjectData } from '../hooks/usePublicProjectData';
import { ElementsWrapper, Sections } from '../style';

export const Buffers = () => {
  const { properties } = usePublicProjectData();
  const buffers = useMemo(
    () => (typeof properties.buffers === 'string' ? JSON.parse(properties.buffers) : properties.buffers) as IBuffer[],
    [properties.buffers]
  );

  return (
    <Sections>
      {buffers?.map((buffer, index) => (
        <ElementsWrapper key={`Buffer-${index}`} isColumnMode={false}>
          {Object.entries(buffer)
            .filter(([, value]) => !!value)
            .map(([key, value], innerIndex) => (
              <PropertySection key={`Buffer-${index}-${innerIndex}`} title={key} value={String(value)} />
            ))}
        </ElementsWrapper>
      ))}
    </Sections>
  );
};
