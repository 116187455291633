import { PublicProject } from '../../types/API/PublicProjects';
import {
  setCurrentPage,
  setFilteredPublicProjects,
  setPublicProjects,
  setTotalPages
} from '../features/public-projects/public-projects-slice';
import { baseApi } from './index';

export const publicProjectsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['PublicProjects']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPublicProjects: builder.query<
        {
          data: PublicProject[];
          total: number;
        },
        {
          itemsPerPage?: number;
          page?: number;
          sorting?: string;
          sortingDirection?: 'asc' | 'desc';
        }
      >({
        query({ itemsPerPage = 10, page = 1, sorting, sortingDirection }) {
          return {
            url: '/public-projects',
            method: 'GET',
            params: {
              itemsPerPage,
              page,
              sorting,
              sortingDirection
            }
          };
        },
        providesTags: ['PublicProjects'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          dispatch(setCurrentPage(1));
          dispatch(setTotalPages(Math.ceil(data.total / 10)));
          dispatch(setPublicProjects(data.data));
          dispatch(setFilteredPublicProjects(data.data.slice(0, 10)));
        }
      }),
      getAllPublicProjects: builder.query<PublicProject[], void>({
        query() {
          return {
            url: '/public-projects/all',
            method: 'GET'
          };
        },
        providesTags: ['PublicProjects'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          dispatch(setPublicProjects(data));
          dispatch(setFilteredPublicProjects(data.slice(0, 10)));
          dispatch(setCurrentPage(1));
          dispatch(setTotalPages(Math.ceil(data.length / 10)));
        }
      }),
      getPublicProjectsWithBiomassData: builder.query<PublicProject[], void>({
        query() {
          return {
            url: '/public-projects/data',
            method: 'GET'
          };
        },
        providesTags: ['PublicProjects'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          dispatch(setPublicProjects(data));
          dispatch(setFilteredPublicProjects(data.slice(0, 10)));
          dispatch(setCurrentPage(1));
          dispatch(setTotalPages(Math.ceil(data.length / 10)));
        }
      }),
      getSignedDownloadUrl: builder.mutation<{ signedUrl: string }, string>({
        query(documentUrl) {
          return {
            url: '/public-projects/get-signed-download-url',
            method: 'POST',
            body: { downloadUrl: documentUrl }
          };
        }
      })
    })
  });

export const {
  useGetPublicProjectsQuery,
  useGetPublicProjectsWithBiomassDataQuery,
  useGetAllPublicProjectsQuery,
  useGetSignedDownloadUrlMutation
} = publicProjectsApi;
