import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setCurrentPage,
  setFilteredPublicProjects
} from '../../../redux/features/public-projects/public-projects-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Pagination from '../../Common/Pagination';
import { Wrapper } from './style';

const PublicProjectsListFooter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { publicProjects, currentPage, perPage, totalPages } = useAppSelector((state) => state.publicProjectsState);
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setCurrentPage(page));
      const filteredProjects = publicProjects.slice((page - 1) * perPage, page * perPage);
      dispatch(setFilteredPublicProjects(filteredProjects));
    },
    [dispatch, publicProjects, perPage]
  );

  return (
    <Wrapper>
      <p>{t('Find your projects, navigate to them and open their data board from here')}</p>
      {totalPages > 1 ? (
        <Pagination
          page={currentPage}
          count={totalPages}
          boundaryCount={1}
          onChange={(_, page) => handlePageChange(page)}
          showFirstButton
          showLastButton
          withWrapperMargin={false}
        />
      ) : null}
    </Wrapper>
  );
};

export default PublicProjectsListFooter;
