import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Common/Icon';
import useDownloadImages from '../../../hooks/useDownloadImages';

import { bbox } from '@turf/bbox';
import { bboxPolygon } from '@turf/bbox-polygon';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { DataLayerEnum } from '../../../redux/features/map/map-slice';
import { setFilteredData, setRasterImage } from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IBiomass } from '../../../types/Geo';
import SliderImage from './SliderImage';
import {
  SliderButtonsWrapper,
  SliderControlButton,
  SliderCTA,
  SliderCTAWrapper,
  SliderDatePickerWrapper,
  SliderNavigationWrapper,
  SliderSlide,
  SliderSlides,
  SliderWrapper
} from './style';

interface Props {
  visible?: boolean;
  dataTestId?: string;
  biomassData: IBiomass;
}

const ImageSlider = ({ visible, dataTestId, biomassData }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { splitDrawnPolygon } = useAppSelector((state) => state.drawState);
  const { dataLayer } = useAppSelector((state) => state.mapState);
  const { mapToolsExpanded, sidebarVisible } = useAppSelector((state) => state.uiState);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const sliderRef: RefObject<HTMLDivElement> = useRef(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const layerRef = useRef<DataLayerEnum | null>(null);

  const [imageReadyStates, setImageReadyStates] = useState<boolean[]>(
    // start loading the last (more recent) image
    biomassData.images.map((_, index) => index === biomassData.images.length - 1)
  );
  const { isDownloading, downloadSingleImage, downloadImages } = useDownloadImages();
  const [dateRanges, setDateRanges] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>({
    startDate: new Date(biomassData.times[0]),
    endDate: new Date(biomassData.times[biomassData.times.length - 1])
  });

  const isAllImagesReady = useMemo(() => imageReadyStates.every((el) => el), [imageReadyStates]);

  const scrollToActiveElement = useCallback(() => {
    const container = sliderRef.current;
    if (container) {
      const activeElement = container.querySelector('.active');

      if (activeElement) {
        const containerRect = container.getBoundingClientRect();
        const activeRect = activeElement.getBoundingClientRect();
        const isFullyVisible = activeRect.left >= containerRect.left && activeRect.right <= containerRect.right;
        if (!isFullyVisible) {
          let scrollPosition = container.scrollLeft;
          if (activeRect.left < containerRect.left) {
            scrollPosition += activeRect.left - containerRect.left;
          } else if (activeRect.right > containerRect.right) {
            scrollPosition += activeRect.right - containerRect.right;
          }
          container.scrollTo({
            left: scrollPosition,
            behavior: 'smooth'
          });
        }
      }
    }
  }, []);

  const handleSetRasterImage = useCallback(
    (index: number) => {
      if (selectedPolygon) {
        let polygonBBox = bboxPolygon(bbox(selectedPolygon));
        if (splitDrawnPolygon?.drawnDataOnTile?.features.length) {
          polygonBBox = bboxPolygon(bbox(splitDrawnPolygon.drawnDataOnTile.features[0]));
        }
        dispatch(
          setRasterImage({
            imageUrl: biomassData?.images[index] || '',
            bboxCoordinates: polygonBBox.geometry.coordinates[0].splice(0, 4)
          })
        );
      }
    },
    [selectedPolygon, dispatch, biomassData, splitDrawnPolygon]
  );

  const handleNextClick = useCallback(() => {
    setIsPlaying(false);
    const lastIndex = (biomassData?.images.length || 0) - 1;
    setActiveIndex((prevIndex) => (prevIndex === lastIndex ? 0 : prevIndex + 1));
    scrollToActiveElement();
  }, [biomassData, scrollToActiveElement]);

  const handlePrevClick = useCallback(() => {
    setIsPlaying(false);
    const lastIndex = (biomassData?.images.length || 0) - 1;
    setActiveIndex((prevIndex) => (prevIndex === 0 ? lastIndex : prevIndex - 1));
    scrollToActiveElement();
  }, [biomassData, scrollToActiveElement]);

  const handleFirstClick = useCallback(() => {
    setIsPlaying(false);
    setActiveIndex(0);
  }, []);

  const handleLastClick = useCallback(() => {
    setIsPlaying(false);
    setActiveIndex((biomassData?.images.length || 0) - 1);
  }, [biomassData]);

  const handleSlideClick = useCallback((index: number) => {
    setIsPlaying(false);
    setActiveIndex(index);
  }, []);

  const handlePlayClick = useCallback(() => {
    setIsPlaying((wasPlaying) => !wasPlaying);
    layerRef.current = dataLayer;
  }, [dataLayer]);

  useEffect(() => {
    if (isPlaying) {
      intervalId.current = setInterval(() => {
        if (activeIndex < (biomassData?.images.length || 0) - 1) {
          setActiveIndex((prevIndex) => prevIndex + 1);
        } else {
          setActiveIndex(0);
        }
      }, 2000);
    } else {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
        layerRef.current = null;
      }
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
        layerRef.current = null;
      }
    };
  }, [isPlaying, biomassData?.images.length, activeIndex]);

  useEffect(() => {
    scrollToActiveElement();
  }, [activeIndex, scrollToActiveElement]);

  useEffect(() => {
    if (visible) {
      handleSetRasterImage(activeIndex);
    }
  }, [visible, activeIndex, handleSetRasterImage]);

  // initial focus set to last, more recent, image
  useEffect(() => {
    setActiveIndex((biomassData?.images.length || 0) - 1);
  }, [biomassData, setActiveIndex]);

  useEffect(() => {
    if (layerRef.current !== dataLayer) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
      setIsPlaying(false);
    }
  }, [dataLayer]);

  const handleCanvasReady = useCallback(
    (index: number) => {
      const haveAnyFalse = imageReadyStates.some((el) => !el);
      const lastWithTrue = imageReadyStates.indexOf(true);

      if (haveAnyFalse && index === lastWithTrue) {
        if (!isUpdating) {
          setIsUpdating(true);

          setTimeout(() => {
            const newImageReadyStates = [...imageReadyStates];
            if (!newImageReadyStates[index - 1]) {
              newImageReadyStates[index - 1] = true;
              setImageReadyStates(newImageReadyStates);
            }

            setIsUpdating(false);
          }, 0);
        }
      }
    },
    [imageReadyStates, isUpdating]
  );

  const renderedSlides = useMemo(
    () =>
      biomassData?.images.map((_, index) => (
        <SliderSlide
          className={activeIndex === index ? 'active' : ''}
          timelineText={new Date(biomassData?.times[index] || '').toLocaleDateString()}
          data-test-id={dataTestId && `${dataTestId}-slide-${index}`}
          active={activeIndex === index}
          key={`image-slider-${index}`}
          onClick={!isAllImagesReady ? undefined : () => handleSlideClick(index)}
          isLoading={!imageReadyStates[index]}
        >
          {imageReadyStates[index] ? (
            <SliderImage
              id={`image-slider-${index}`}
              dataTestId={dataTestId && `${dataTestId}-image-${index}`}
              onReady={!isAllImagesReady ? () => handleCanvasReady(index) : undefined}
              data={biomassData?.images[index] || ''}
            />
          ) : null}
        </SliderSlide>
      )),
    [
      activeIndex,
      dataTestId,
      biomassData?.images,
      biomassData?.times,
      handleCanvasReady,
      handleSlideClick,
      imageReadyStates,
      isAllImagesReady
    ]
  );

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'repositionSliderImages') {
        setTimeout(() => {
          scrollToActiveElement();
        }, 300);
      }
    });
  }, [scrollToActiveElement]);

  const updateDeforestationData = useCallback(
    (newStartDate: Date, newEndDate: Date) => {
      setDateRanges({
        startDate: newStartDate,
        endDate: newEndDate
      });

      dispatch(
        setFilteredData({
          timeStart: newStartDate.toISOString(),
          timeEnd: newEndDate.toISOString()
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setImageReadyStates(biomassData?.images.map(() => true) || []);
  }, [biomassData?.images]);

  return (
    <SliderWrapper
      active={visible}
      data-test-id={dataTestId}
      isDownloading={isDownloading}
      isMapToolsExpanded={mapToolsExpanded}
      isSidebarVisible={sidebarVisible}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '130px 1fr', width: '100%', gap: '20px' }}>
        <SliderDatePickerWrapper>
          <DateRangePicker
            id={'imagesRange'}
            name={'imagesRange'}
            placeholder={t('Select...')}
            character={'  -  '}
            format={'yyyy-MM'}
            placement={'topStart'}
            shouldDisableDate={(date) => {
              return (
                date.getTime() < new Date(biomassData.times[0]).getTime() ||
                date.getTime() > new Date(biomassData.times[biomassData.times.length - 1]).getTime()
              );
            }}
            ranges={[
              {
                label: t('Reset'),
                value: [new Date(biomassData.times[0]), new Date(biomassData.times[biomassData.times.length - 1])]
              }
            ]}
            value={dateRanges ? [dateRanges.startDate, dateRanges.endDate] : null}
            onOk={(value) => {
              updateDeforestationData(value[0], value[1]);
            }}
            onShortcutClick={() => {
              updateDeforestationData(
                new Date(biomassData.times[0]),
                new Date(biomassData.times[biomassData.times.length - 1])
              );
            }}
          />
        </SliderDatePickerWrapper>
        <SliderSlides
          ref={sliderRef}
          onScroll={() => (!isPlaying ? setIsPlaying(false) : undefined)}
          isMapToolsExpanded={mapToolsExpanded}
        >
          {renderedSlides}
        </SliderSlides>
      </div>
      <SliderNavigationWrapper active={visible} disabled={!isAllImagesReady}>
        <SliderButtonsWrapper>
          <SliderControlButton rotateIcon onClick={handleFirstClick}>
            <Icon variant="ARROW_RIGHT_SKIP" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton rotateIcon onClick={handlePrevClick}>
            <Icon variant="ARROW_RIGHT" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton isPlay={!isPlaying} onClick={handlePlayClick}>
            <Icon variant={isPlaying ? 'PAUSE' : 'PLAY'} size={isPlaying ? 20 : 16} color="white" />
          </SliderControlButton>
          <SliderControlButton onClick={handleNextClick}>
            <Icon variant="ARROW_RIGHT" size={13} color="white" />
          </SliderControlButton>
          <SliderControlButton onClick={handleLastClick}>
            <Icon variant="ARROW_RIGHT_SKIP" size={13} color="white" />
          </SliderControlButton>
        </SliderButtonsWrapper>
        <SliderCTAWrapper>
          <SliderCTA
            onClick={() => downloadSingleImage(activeIndex)}
            isMapToolsExpanded={mapToolsExpanded}
            isSingleDownload
            isSidebarVisible={sidebarVisible}
          >
            <Icon variant="DOWNLOAD" color="purple" size={16} />
            <span>{t('Download this frame')}</span>
            <span>{t('Current')}</span>
          </SliderCTA>
          <SliderCTA onClick={downloadImages} isMapToolsExpanded={mapToolsExpanded} isSidebarVisible={sidebarVisible}>
            <Icon variant="DOWNLOAD" color="white" size={16} />
            <span>{t('Download Images')}</span>
            <span>{t('Images')}</span>
          </SliderCTA>
        </SliderCTAWrapper>
      </SliderNavigationWrapper>
    </SliderWrapper>
  );
};

export default ImageSlider;
