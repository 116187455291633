import { useCallback, useEffect, useRef } from 'react';
import { theme } from '../../../../theme/Theme';
import { BaseCanvasProps, BoundingBox, useCanvasRendering } from '../common';

interface Props extends BaseCanvasProps {
  multiPolygon: GeoJSON.Feature<GeoJSON.MultiPolygon>;
  fillColor?: string;
  strokeColor?: string;
  lineWidth?: number;
}

const MultiPolygonCanvas = ({
  multiPolygon,
  width = 400,
  height = 400,
  fillColor = 'rgba(135, 139, 235, 0.6)',
  strokeColor = theme.colors.purple,
  dataTestId,
  lineWidth = 1
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  /**
   * Calculates the bounding box of a MultiPolygon's coordinates
   */
  const getBoundingBox = useCallback((coordinates: GeoJSON.Position[][][]): BoundingBox => {
    return coordinates.flat(2).reduce(
      (box, coord) => {
        const [x, y] = coord;
        box.minX = Math.min(box.minX, x);
        box.maxX = Math.max(box.maxX, x);
        box.minY = Math.min(box.minY, y);
        box.maxY = Math.max(box.maxY, y);
        return box;
      },
      { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
    );
  }, []);

  /**
   * Draws a MultiPolygon on a canvas
   */
  const drawMultiPolygon = useCallback(
    (ctx: CanvasRenderingContext2D, ratio: number, xOffset: number, yOffset: number) => {
      ctx.fillStyle = fillColor;
      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = lineWidth;

      multiPolygon.geometry.coordinates.forEach((polygon) => {
        const coordinates = polygon[0];
        ctx.beginPath();
        ctx.moveTo(coordinates[0][0] * ratio + xOffset, height - (coordinates[0][1] * ratio + yOffset));

        for (let i = 1; i < coordinates.length; i++) {
          const [x, y] = coordinates[i];
          ctx.lineTo(x * ratio + xOffset, height - (y * ratio + yOffset));
        }

        ctx.fill();
        ctx.stroke();
      });
    },
    [height, fillColor, lineWidth, strokeColor, multiPolygon.geometry.coordinates]
  );

  const { renderCanvas } = useCanvasRendering(width, height, drawMultiPolygon);

  useEffect(() => {
    if (canvasRef.current) {
      const coordinates = multiPolygon.geometry.coordinates;
      const boundingBox = getBoundingBox(coordinates);
      renderCanvas(canvasRef.current, boundingBox);
    }
  }, [multiPolygon, getBoundingBox, renderCanvas]);

  return <canvas data-test-id={dataTestId} ref={canvasRef} width={width} height={height} />;
};

export default MultiPolygonCanvas;
