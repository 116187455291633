import Pagination from '@mui/material/Pagination';
import { PaginationWrapper } from './style';

export interface Props {
  page: number;
  count: number;
  itemsPerPage?: number;
  totalItems?: number;
  onChange: (event: any, value: number) => void;
  boundaryCount?: number;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  withWrapperMargin?: boolean;
}

const PaginationComponent = ({
  page,
  count,
  onChange,
  boundaryCount = 1,
  showFirstButton = true,
  showLastButton = true,
  withWrapperMargin = true
}: Props) => {
  return (
    <PaginationWrapper withWrapperMargin={withWrapperMargin}>
      <Pagination
        page={page}
        count={count}
        boundaryCount={boundaryCount}
        onChange={onChange}
        showFirstButton={showFirstButton}
        showLastButton={showLastButton}
      />
    </PaginationWrapper>
  );
};

export default PaginationComponent;
