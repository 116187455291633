import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import DataBoard from '../../components/DataBoard';
import DataBoardFooter from '../../components/DataBoard/Footer';
import DataBoardHeader from '../../components/DataBoard/Header';
import EditRegion from '../../components/EditRegion';
import EditRegionFooter from '../../components/EditRegion/Footer';
import EditRegionHeader from '../../components/EditRegion/Header';
import Notifications from '../../components/Notifications';
import NotificationsFooter from '../../components/Notifications/Footer';
import NotificationsHeader from '../../components/Notifications/Header';
import { PublicProjectsContent } from '../../components/PublicProjects/Content';
import PublicProjectsHeader from '../../components/PublicProjects/Header';
import PublicProjectsList from '../../components/PublicProjectsList';
import PublicProjectsListFooter from '../../components/PublicProjectsList/Footer';
import PublicProjectsListHeader from '../../components/PublicProjectsList/Header';
import SavedRegions from '../../components/SavedRegions';
import SavedRegionsHeader from '../../components/SavedRegions/Header';
import { resetDrawSliceState } from '../../redux/features/draw/draw-slice';
import { setDataLayer } from '../../redux/features/map/map-slice';
import { resetUIState, setShowPublicProjectsList, setShowSidebar } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IPublicProjectMetadata } from '../../types/API/Region';
import { useMapContext } from '../Map';
import { usePolygonContext } from '../Polygon';
import { Props } from '../types';
import { defaultState, ISidebarContext } from './types';

const SidebarContext = createContext<ISidebarContext>(defaultState);

export const SidebarProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { showSavedRegions, showNotifications, showPublicProjectsList } = useAppSelector((state) => state.uiState);
  const { messages } = useAppSelector((state) => state.socketState);
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { drawnFeatures } = useAppSelector((state) => state.drawState);
  const { resetPolygonData } = usePolygonContext();
  const { removeMapSelection } = useMapContext();

  useEffect(() => {
    if (
      selectedPolygon ||
      drawnFeatures.length > 0 ||
      showSavedRegions ||
      showNotifications ||
      showPublicProjectsList
    ) {
      dispatch(setShowSidebar(true));
    }
  }, [dispatch, drawnFeatures, selectedPolygon, showNotifications, showSavedRegions, showPublicProjectsList]);

  const onClose = useCallback(() => {
    const commonActions = () => {
      removeMapSelection();
      dispatch(resetDrawSliceState(true));
      dispatch(setDataLayer(null));
      resetPolygonData();
    };

    if ((selectedPolygon?.properties as unknown as IPublicProjectMetadata)?.documents) {
      commonActions();
      dispatch(setShowPublicProjectsList(true));
    } else {
      commonActions();
      dispatch(resetUIState());
    }
  }, [removeMapSelection, resetPolygonData, dispatch, selectedPolygon]);

  const publicProjectsListContent = useMemo(
    () => ({
      content: <PublicProjectsList />,
      header: <PublicProjectsListHeader />,
      footer: <PublicProjectsListFooter />
    }),
    []
  );

  const publicProjectContent = useMemo(
    () => ({
      content: <PublicProjectsContent />,
      header: <PublicProjectsHeader />,
      footer: null
    }),
    []
  );

  const selectedPolygonContent = useMemo(
    () => ({
      content: <DataBoard />,
      header: <DataBoardHeader />,
      footer: <DataBoardFooter />
    }),
    []
  );

  const editRegionContent = useMemo(
    () => ({
      content: <EditRegion />,
      header: <EditRegionHeader />,
      footer: <EditRegionFooter />
    }),
    []
  );

  const userTilesContent = useMemo(
    () => ({
      content: <SavedRegions />,
      header: <SavedRegionsHeader />,
      footer: null
    }),
    []
  );

  const notificationsContent = useMemo(
    () => ({
      content: <Notifications />,
      header: <NotificationsHeader />,
      footer: messages.length > 0 ? <NotificationsFooter /> : null
    }),
    [messages.length]
  );

  const generateSidebarContent = useCallback(() => {
    if ((selectedPolygon?.properties as unknown as IPublicProjectMetadata)?.documents) {
      return publicProjectContent;
    }
    if (selectedPolygon && drawnFeatures.length === 0) {
      return selectedPolygonContent;
    }

    if (drawnFeatures.length > 0 && !selectedPolygon) {
      return editRegionContent;
    }

    if (showSavedRegions && !selectedPolygon && drawnFeatures.length === 0) {
      return userTilesContent;
    }

    if (showNotifications && !selectedPolygon && drawnFeatures.length === 0) {
      return notificationsContent;
    }

    if (showPublicProjectsList && !selectedPolygon && drawnFeatures.length === 0) {
      return publicProjectsListContent;
    }

    return {
      header: null,
      footer: null,
      content: null
    };
  }, [
    drawnFeatures.length,
    editRegionContent,
    notificationsContent,
    publicProjectContent,
    publicProjectsListContent,
    selectedPolygonContent,
    userTilesContent,
    selectedPolygon,
    showNotifications,
    showSavedRegions,
    showPublicProjectsList
  ]);

  return (
    <SidebarContext.Provider
      value={{
        generateSidebarContent,
        onClose
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
