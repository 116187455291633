import { IPublicProjectMetadata } from './Region';

export enum EnumProjectType {
  'REDD+' = 'REDD+',
  'ARR' = 'ARR',
  'IFM' = 'IFM'
}

export type IRegionPolygonProperties = GeoJSON.GeoJsonProperties & {
  pdfUrl: string;
  projectType: EnumProjectType;
  continent?: ContinentType;
  country?: string;
};

export enum ContinentType {
  'AFRICA' = 'Africa',
  'ASIA' = 'Asia',
  'AUSTRALIA' = 'Australia',
  'OCEANIA' = 'Oceania',
  'ANTARCTICA' = 'Antarctica',
  'EUROPE' = 'Europe',
  'NORTH_AMERICA' = 'North America',
  'SOUTH_AMERICA' = 'South America'
}

export interface PublicProject {
  id: string;
  type: string;
  properties: IPublicProjectMetadata;
  geometry: GeoJSON.Feature<
    | GeoJSON.Polygon
    | GeoJSON.MultiPolygon
    | GeoJSON.Point
    | GeoJSON.LineString
    | GeoJSON.MultiLineString
    | GeoJSON.MultiPoint
  >;
  createdAt: Date;
  updatedAt: Date;
  hasBiomassData: boolean;
  name: string;
}

export interface UpdatePublicProjectDto {
  hasBiomassData?: boolean;
  properties?: Partial<IPublicProjectMetadata>;
  name?: string;
}

export interface CreatePublicProjectDto {
  polygon: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>;
  hasBiomassData?: boolean;
  properties: IPublicProjectMetadata;
  name: string;
}
