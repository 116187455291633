import styled from 'styled-components';
import { MAPBOX_MAP_STYLES } from '../../../constants/map';

export const StyleName = styled.p`
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  text-transform: capitalize;
  transition: ${({ theme }) => theme.transition};
`;

export const StylesList = styled.div<{ active: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  column-gap: 10px;
  grid-template-rows: 1fr;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 5px;
  border-radius: 6px;
  transition: ${({ theme }) => theme.transition};
  transition-delay: 300ms;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: translate3d(0, ${({ active }) => (active ? '10px' : 0)}, 0);
  position: absolute;
  bottom: 10px;
  right: 55px;
  background-color: ${({ theme }) => theme.colors.black};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
`;

export const StyleChangeContainer = styled.div.attrs({
  className: 'style-change-container'
})<{ active: boolean; name: keyof typeof MAPBOX_MAP_STYLES }>`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  position: absolute;
  bottom: 120px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.colors.black};
  background-image: url(/assets/map_${({ name }) => name}.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid ${({ theme }) => theme.colors.darkGrey};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  > ${StyleName} {
    opacity: ${({ active }) => (active ? 1 : 0)};
    color: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    > ${StyleName} {
      opacity: 1;
    }
  }
`;

export const StylesListItem = styled.div<{
  active: boolean;
  name: keyof typeof MAPBOX_MAP_STYLES;
}>`
  width: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/assets/map_${({ name }) => name}.png);
  height: 30px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  font-weight: ${({ active }) => (active ? 'bold' : 400)};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.purple};
    transition: ${({ theme }) => theme.transition};
    opacity: ${({ active }) => (active ? 0.5 : 0)};
    border-radius: 6px;
  }

  ${StyleName} {
    opacity: 1;
    z-index: 1;
    color: ${({ theme }) => theme.colors.white};
  }
`;
