import center from '@turf/center';
import { MouseEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapboxGeoJSONFeature, useMap } from 'react-map-gl';
import { useOnClickOutside } from 'usehooks-ts';
import { INITIAL_MAP_VIEW_STATE, PUBLIC_SOURCE_NAME } from '../../constants/map';
import { useMapContext } from '../../context/Map';
import { usePolygonContext } from '../../context/Polygon';
import { useAppSelector } from '../../redux/hooks';
import { PublicProject } from '../../types/API/PublicProjects';
import Icon from '../Common/Icon';
import LineStringCanvas from '../Common/ShapeCanvas/LineString/LineStringCanvas';
import MultiLineStringCanvas from '../Common/ShapeCanvas/MultiLineString/MultiLineStringCanvas';
import MultiPointCanvas from '../Common/ShapeCanvas/MultiPoint/MultiPointCanvas';
import MultiPolygonCanvas from '../Common/ShapeCanvas/Multipolygon/MultipolygonCanvas';
import PointCanvas from '../Common/ShapeCanvas/Point/PointCanvas';
import PolygonCanvas from '../Common/ShapeCanvas/Polygon/PolygonCanvas';
import { prepareCleanedFeature } from '../Map/utils';
import {
  Item,
  ItemContentWrapper,
  ItemMap,
  ItemSection,
  ItemsList,
  ItemText,
  ItemTitle,
  MenuItem,
  MenuOptions,
  MenuToggle
} from '../SavedRegions/style';
import PublicProjectsSearch from './Search';

const PublicProjectsList = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { filteredPublicProjects } = useAppSelector((state) => state.publicProjectsState);
  const { resetPolygonData } = usePolygonContext();
  const { mapRoot } = useMap();
  const { handleTileClick } = useMapContext();
  const [openMenu, setOpenMenu] = useState(-1);

  useOnClickOutside(menuRef, () => setOpenMenu(-1), 'mouseup');

  const handleShowOnMap = useCallback(
    (e: MouseEvent<HTMLButtonElement>, data: PublicProject) => {
      // Prevents the click event from bubbling up to the parent element
      e.stopPropagation();
      const centerPoint = center(data.geometry);
      mapRoot?.flyTo({
        center: [centerPoint.geometry.coordinates[0], centerPoint.geometry.coordinates[1]],
        zoom: INITIAL_MAP_VIEW_STATE.zoom + 2
      });
    },
    [mapRoot]
  );

  const handleProjectClick = useCallback(
    (el: PublicProject) => {
      resetPolygonData();
      const cleanedFeature = prepareCleanedFeature(el as unknown as MapboxGeoJSONFeature);
      const cleanedFeatureWithName = { ...cleanedFeature, name: el.name };
      handleTileClick(cleanedFeatureWithName, PUBLIC_SOURCE_NAME, false);
    },
    [resetPolygonData, handleTileClick]
  );

  const toggleOptionsMenu = useCallback((e: MouseEvent<HTMLDivElement>, index: number) => {
    // Prevents the click event from bubbling up to the parent element
    e.stopPropagation();
    setOpenMenu((prevIndex) => (prevIndex === index ? -1 : index));
  }, []);

  const renderMapCanvas = useCallback((project: PublicProject) => {
    switch ((project as unknown as GeoJSON.Feature).geometry.type) {
      case 'Polygon':
        return (
          <PolygonCanvas polygon={project as unknown as GeoJSON.Feature<GeoJSON.Polygon>} width={71} height={71} />
        );
      case 'MultiPolygon':
        return (
          <MultiPolygonCanvas
            multiPolygon={project as unknown as GeoJSON.Feature<GeoJSON.MultiPolygon>}
            width={71}
            height={71}
          />
        );
      case 'Point':
        return <PointCanvas point={project as unknown as GeoJSON.Feature<GeoJSON.Point>} width={71} height={71} />;
      case 'LineString':
        return <LineStringCanvas lineString={project as unknown as GeoJSON.Feature<GeoJSON.LineString>} />;
      case 'MultiLineString':
        return (
          <MultiLineStringCanvas
            multiLineString={project as unknown as GeoJSON.Feature<GeoJSON.MultiLineString>}
            width={71}
            height={71}
          />
        );
      case 'MultiPoint':
        return (
          <MultiPointCanvas
            multiPoint={project as unknown as GeoJSON.Feature<GeoJSON.MultiPoint>}
            width={71}
            height={71}
          />
        );
      default:
        return null;
    }
  }, []);

  return (
    <>
      <PublicProjectsSearch />
      <ItemsList data-test-id="saved-regions-list">
        {filteredPublicProjects.map((project, index) => {
          return (
            <Item
              key={project.id}
              data-id={project.id}
              onClick={() => handleProjectClick(project)}
              data-original-geometry={JSON.stringify(project.geometry)}
              data-index={index}
              data-test-id={`saved-regions-region-${index}`}
            >
              <ItemMap>{renderMapCanvas(project)}</ItemMap>
              <ItemContentWrapper>
                <ItemSection>
                  <ItemTitle data-test-id="saved-regions-region-name">{project.name}</ItemTitle>
                  <MenuToggle onClick={(e) => toggleOptionsMenu(e, index)}>
                    <Icon variant="MORE" size={24} color="white" />
                  </MenuToggle>
                  <MenuOptions isOpen={index === openMenu} ref={menuRef}>
                    <MenuItem onClick={() => handleProjectClick(project)}>{t('Show Data Board')}</MenuItem>
                    <MenuItem onClick={(e) => handleShowOnMap(e, project)}>{t('See on Map')}</MenuItem>
                  </MenuOptions>
                </ItemSection>
                <ItemSection>
                  <ItemText>
                    ID: <strong>{project.properties.projectId}</strong>
                  </ItemText>
                </ItemSection>
              </ItemContentWrapper>
            </Item>
          );
        })}
      </ItemsList>
    </>
  );
};

export default PublicProjectsList;
