import Tooltip from '@mui/material/Tooltip';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MAP_TOOLS_STEPS } from '../../constants/joyride';
import { HINT_DISMISSED_LS } from '../../constants/user';
import { useMapContext } from '../../context/Map';
import { usePolygonContext } from '../../context/Polygon';
import useJoyride from '../../hooks/useJoyride';
import { resetDrawSliceState, setIsDrawing } from '../../redux/features/draw/draw-slice';
import { removeSteps } from '../../redux/features/joyride/joyride-slice';
import { DataLayerEnum, setDataLayer, setSources } from '../../redux/features/map/map-slice';
import { openModal } from '../../redux/features/modal/modal-slice';
import { setRasterImage } from '../../redux/features/region/region-slice';
import { resetUIState, setMapToolsExpanded, setShowSliderImagesComponent } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { theme } from '../../theme/Theme';
import { IPublicProjectMetadata } from '../../types/API/Region';
import Checkbox from '../Common/Checkbox';
import Icon from '../Common/Icon';
import MapSearch from '../MapSearch';
import {
  DataLayer,
  //DataLayerDescription,
  DataLayerIcon,
  DataLayerRadio,
  DataLayersWrapper,
  DataLayerTitle,
  Options,
  OptionsButton,
  OptionsSection,
  OptionsSectionTitle,
  Toggle,
  Wrapper
} from './style';

const MapTools = () => {
  const { t } = useTranslation();
  const searchRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const { resetPolygonData } = usePolygonContext();
  const { removeMapSelection } = useMapContext();

  const { user } = useAppSelector((state) => state.userState);
  const { publicProjects } = useAppSelector((state) => state.publicProjectsState);
  const { sources, dataLayer } = useAppSelector((state) => state.mapState);
  const { isDrawing } = useAppSelector((state) => state.drawState);
  const { userTiles, selectedPolygon, carbonLoading, forestCoverLoading, carbonAccounting, forestCover } =
    useAppSelector((state) => state.regionState);
  const { mapToolsExpanded } = useAppSelector((state) => state.uiState);

  const onSourcesClick = useCallback(
    (source: 'user' | 'default' | 'public') => {
      const newSources = sources.includes(source) ? sources.filter((s) => s !== source) : [...sources, source];
      dispatch(setSources(newSources));
    },
    [dispatch, sources]
  );

  const dismissToasts = useCallback(() => {
    ['hint-draw-first', 'hint', 'hint-draw-second', 'hint'].forEach(toast.dismiss);
  }, []);

  const resetUI = useCallback(() => {
    dispatch(resetUIState());
    dispatch(resetDrawSliceState(true));
    dispatch(setDataLayer(null));
    localStorage.setItem(HINT_DISMISSED_LS, 'true');
  }, [dispatch]);

  const onUploadClick = useCallback(() => {
    dismissToasts();
    if (user?.role !== 'free') {
      resetPolygonData(false);
      resetUI();
      toast.dismiss('hint');
      dispatch(openModal('uploadShapefile'));
    }
  }, [dismissToasts, dispatch, resetPolygonData, resetUI, user?.role]);

  const onOpenEditMode = useCallback(() => {
    resetUI();
    dismissToasts();

    if (!isDrawing) {
      resetPolygonData(true);
      dispatch(setIsDrawing(true));
      removeMapSelection();
    } else {
      resetPolygonData(false);
    }
  }, [resetUI, dismissToasts, isDrawing, resetPolygonData, dispatch, removeMapSelection]);

  const onToggle = useCallback(() => {
    dispatch(setMapToolsExpanded(!mapToolsExpanded));
    dispatch(removeSteps(MAP_TOOLS_STEPS(t)));
    window.postMessage('repositionSliderImages', '*');
  }, [dispatch, mapToolsExpanded, t]);

  useJoyride(MAP_TOOLS_STEPS(t), mapToolsExpanded === false);

  return (
    <Wrapper open={mapToolsExpanded}>
      <Toggle open={mapToolsExpanded} onClick={onToggle} id="map-tools">
        {t('Map Tools')}
        <Icon variant="ARROWS_RIGHT" color="white" size={20} />
      </Toggle>
      <Options open={mapToolsExpanded} dataBoardVisible={!!selectedPolygon}>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          {mapToolsExpanded ? (
            <MapSearch ref={searchRef} />
          ) : (
            <Tooltip
              followCursor
              arrow
              PopperProps={{
                disablePortal: true
              }}
              placement="bottom"
              disableFocusListener
              disableTouchListener
              title={t('Search')}
            >
              <OptionsButton
                open={mapToolsExpanded}
                onClick={() => {
                  onToggle();
                  setTimeout(() => {
                    searchRef.current?.getElementsByTagName('input')[0].focus();
                  }, 300);
                }}
              >
                <Icon color={'white'} variant={'SEARCH'} size={20} />
              </OptionsButton>
            </Tooltip>
          )}
          {mapToolsExpanded ? (
            <OptionsSection id="map-tools-layers">
              <OptionsSectionTitle>
                <Icon variant="MAP_PIN" color="gray" size={20} />
                {t('View')}
              </OptionsSectionTitle>
              <Checkbox
                checked={sources.includes('default')}
                onChange={() => onSourcesClick('default')}
                label={t('CYCLOPS covered areas')}
                dataTestId="map-tools-checkbox-default-regions"
                unfilledColor={theme.colors.green}
                filledColor={theme.colors.green}
              />
              <Checkbox
                checked={sources.includes('public') && publicProjects.length > 0}
                onChange={() => onSourcesClick('public')}
                label={t('Public projects')}
                dataTestId="map-tools-checkbox-public-projects"
                unfilledColor={theme.colors.purple}
                filledColor={theme.colors.purple}
                disabled={publicProjects.length === 0}
              />
              <Checkbox
                checked={sources.includes('user') && userTiles.length > 0}
                onChange={() => onSourcesClick('user')}
                label={t('My saved projects')}
                dataTestId="map-tools-checkbox-saved-regions"
                unfilledColor={theme.colors.blueNavy}
                filledColor={theme.colors.blueNavy}
                disabled={userTiles.length === 0}
              />
            </OptionsSection>
          ) : (
            <Tooltip
              followCursor
              arrow
              PopperProps={{
                disablePortal: true
              }}
              placement="bottom"
              disableFocusListener
              disableTouchListener
              title={t('View')}
            >
              <OptionsButton open={mapToolsExpanded} onClick={onToggle}>
                <Icon color={'white'} variant={'MAP_PIN'} size={20} />
              </OptionsButton>
            </Tooltip>
          )}
          {mapToolsExpanded ? (
            <OptionsSection id="map-tools-buttons">
              <OptionsSectionTitle open={mapToolsExpanded}>
                <Icon variant="DRAW_BOLD" color="gray" size={20} />
                {t('Create geospatial region')}
              </OptionsSectionTitle>
              <OptionsButton onClick={onOpenEditMode} data-test-id={'map-tools-draw-button'} open={mapToolsExpanded}>
                <Icon size={24} color={'blackI'} variant="DRAW_BOLD" />
                {!isDrawing ? t('Draw region') : t('Cancel drawing')}
              </OptionsButton>
              <OptionsButton
                open={mapToolsExpanded}
                onClick={onUploadClick}
                data-test-id={'map-tools-upload-button'}
                disabled={!user || user?.role === 'free'}
                isForPro={!user || user?.role === 'free'}
              >
                <Icon size={24} color="blackI" variant="UPLOAD_BOLD" />
                {t('Upload GIS file')}
              </OptionsButton>
            </OptionsSection>
          ) : (
            <>
              <Tooltip
                followCursor
                arrow
                PopperProps={{
                  disablePortal: true
                }}
                placement="bottom"
                disableFocusListener
                disableTouchListener
                title={!isDrawing ? t('Draw') : t('Cancel')}
              >
                <OptionsButton open={mapToolsExpanded} onClick={onOpenEditMode}>
                  <Icon color={isDrawing ? 'red' : 'white'} variant={'DRAW_BOLD'} size={20} />
                </OptionsButton>
              </Tooltip>
              <Tooltip
                followCursor
                arrow
                PopperProps={{
                  disablePortal: true
                }}
                placement="bottom"
                disableFocusListener
                disableTouchListener
                title={t('Upload')}
              >
                <OptionsButton open={mapToolsExpanded} onClick={onUploadClick}>
                  <Icon color={'white'} variant={'UPLOAD_BOLD'} size={20} />
                </OptionsButton>
              </Tooltip>
            </>
          )}
        </div>
        {selectedPolygon &&
        !(selectedPolygon.properties as unknown as IPublicProjectMetadata).documents &&
        ((!carbonLoading && !forestCoverLoading) || forestCover || carbonAccounting) ? (
          mapToolsExpanded ? (
            <>
              <OptionsSection id="map-tools-buttons">
                <OptionsSectionTitle id="map-tools-layers" open={mapToolsExpanded}>
                  <Icon size={20} color="white" variant="LAYERS" />
                  {t('Data Layers')}
                </OptionsSectionTitle>

                <DataLayersWrapper>
                  {Object.entries(DataLayerEnum).map(([key, value]) => (
                    <DataLayer key={key} active={dataLayer === value} data-test-id={`map-tools-data-layer-${value}`}>
                      <DataLayerIcon
                        style={{
                          backgroundImage: `url(/assets/layers/${value.replace(/([A-Z])/g, '-$1').toLowerCase()}.png)`
                        }}
                      />
                      <DataLayerTitle>{key}</DataLayerTitle>
                      <DataLayerRadio
                        name="data-layer"
                        value={value}
                        checked={dataLayer === value}
                        onChange={() => {
                          dispatch(setDataLayer(dataLayer === value ? null : value));
                        }}
                        onClick={() => {
                          dispatch(setDataLayer(dataLayer === value ? null : value));

                          if (dataLayer === value) {
                            dispatch(setShowSliderImagesComponent(false));
                            dispatch(setRasterImage(null));
                          } else {
                            dispatch(setShowSliderImagesComponent(true));
                          }

                          // scroll to the selected element in checkbox by name and checked true
                          const element = document.querySelector(`input[name="data-layer"][value="${value}"]`);
                          if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                          }
                        }}
                      />
                      {/*{dataLayer === layer ? (
                        <DataLayerDescription>Lorem ipsum dolor sit amet.</DataLayerDescription>
                      ) : null}*/}
                    </DataLayer>
                  ))}
                </DataLayersWrapper>
              </OptionsSection>
            </>
          ) : (
            <Tooltip
              followCursor
              arrow
              PopperProps={{
                disablePortal: true
              }}
              placement="bottom"
              disableFocusListener
              disableTouchListener
              title={t('Layers')}
            >
              <OptionsButton onClick={onToggle}>
                <Icon size={20} color="white" variant="LAYERS" />
              </OptionsButton>
            </Tooltip>
          )
        ) : null}
      </Options>
    </Wrapper>
  );
};

export default MapTools;
