import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DropdownMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { toast } from 'react-toastify';
import { INITIAL_MAP_VIEW_STATE } from '../../../../constants/map';
import { useMapContext } from '../../../../context/Map';
import { usePolygonContext } from '../../../../context/Polygon';
import { useSocketContext } from '../../../../context/Socket';
import { useLogoutMutation } from '../../../../redux/api/authApi';
import { useGetFromCookieQuery } from '../../../../redux/api/userApi';
import { resetDrawSliceState } from '../../../../redux/features/draw/draw-slice';
import { setDataLayer } from '../../../../redux/features/map/map-slice';
import { closeModal, openModal } from '../../../../redux/features/modal/modal-slice';
import { resetUIState, setShowSavedRegions } from '../../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import {
  AvatarSX,
  MenuItemSx,
  PaperSX,
  StyledBadge,
  UserAvatar,
  UserInfoWrapper,
  UserName,
  UserRole,
  Wrapper
} from './style';

interface Props {
  version?: 'normal' | 'admin';
}

const Menu = ({ version = 'normal' }: Props) => {
  useGetFromCookieQuery();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { disconnect } = useSocketContext();
  const { mapRoot } = useMap();
  const { resetPolygonData } = usePolygonContext();
  const { removeMapSelection } = useMapContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { user } = useAppSelector((state) => state.userState);
  const { userTiles } = useAppSelector((state) => state.regionState);
  const [logout, { isLoading }] = useLogoutMutation();

  /**
   * Function responsible for displaying initials from the user data
   */
  const renderNameString = useCallback(() => {
    const firstLetter = user?.firstName && user?.firstName.length > 0 ? user.firstName.charAt(0).toUpperCase() : 'C';
    const lastLetter = user?.lastName && user?.lastName.length > 0 ? user.lastName.charAt(0).toUpperCase() : 'Y';

    return `${firstLetter}${lastLetter}`;
  }, [user]);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLinkClick = useCallback(() => {
    toast.dismiss('hint-draw-first');
    toast.dismiss('hint');
    toast.dismiss('hint-draw-second');
    resetPolygonData();
    removeMapSelection();
    dispatch(closeModal());
    handleCloseMenu();
    dispatch(resetDrawSliceState(true));
    dispatch(resetUIState());
    dispatch(setDataLayer(null));

    if (mapRoot) {
      mapRoot.flyTo(INITIAL_MAP_VIEW_STATE);
    }
  }, [dispatch, handleCloseMenu, mapRoot, removeMapSelection, resetPolygonData]);

  const handleOpenSettings = useCallback(() => {
    dispatch(closeModal());
    handleCloseMenu();
    dispatch(openModal('userSettings'));
  }, [dispatch, handleCloseMenu]);

  const handleOpenAccount = useCallback(() => {
    handleLinkClick();
    dispatch(openModal('account'));
  }, [handleLinkClick, dispatch]);

  const handleOpenSavedRegions = useCallback(() => {
    handleLinkClick();
    dispatch(setShowSavedRegions(true));
  }, [dispatch, handleLinkClick]);

  const handleLogout = useCallback(() => {
    if (!isLoading) {
      disconnect();
      setTimeout(async () => {
        await logout({ isAdmin: false });
      }, 300);
    }
  }, [disconnect, isLoading, logout]);

  const hasNewSavedRegions = useMemo(() => {
    return userTiles.some(
      (region) =>
        new Date(String(region.dataUpdatedAt)) > new Date(String(region.createdAt)) &&
        !region.hasCheckedUpdate &&
        region.isSubscribed &&
        user?.role !== 'free'
    );
  }, [user?.role, userTiles]);

  return user ? (
    <>
      <Wrapper isFree={user.role === 'free'} id="menu-user">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'dropdown-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <StyledBadge
            overlap="circular"
            variant="dot"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            invisible={!hasNewSavedRegions}
            data-test-id="menu-new-regions-badge"
          >
            <p>{user.role || 'Free'}</p>
            <Avatar sx={AvatarSX(user?.role || 'free')} data-test-id="menu-avatar">
              {renderNameString()}
            </Avatar>
          </StyledBadge>
        </IconButton>
      </Wrapper>
      <DropdownMenu
        anchorEl={anchorEl}
        id="dropdown-menu"
        open={open}
        onClose={handleCloseMenu}
        transformOrigin={{
          horizontal: version === 'normal' ? 'right' : 38,
          vertical: -16
        }}
        PaperProps={{
          elevation: 0,
          sx: PaperSX(version)
        }}
      >
        {version === 'normal' ? (
          <div>
            <UserInfoWrapper>
              <UserAvatar isFree={user.role === 'free'}>{renderNameString()}</UserAvatar>
              {user.firstName && user.lastName ? (
                <UserName>
                  {user.firstName} {user.lastName}
                </UserName>
              ) : null}
              <UserRole isFree={user.role === 'free'}>{user.role || 'Free'}</UserRole>
            </UserInfoWrapper>
            {user.role === 'admin' ? (
              <MenuItem
                data-test-id="menu-link-admin"
                onClick={handleLinkClick}
                component="a"
                href="/admin"
                sx={MenuItemSx}
              >
                {t('Admin panel')}
              </MenuItem>
            ) : null}
            <MenuItem data-test-id="menu-link-user-settings" onClick={handleOpenSettings} sx={MenuItemSx}>
              {t('Settings')}
            </MenuItem>
            <MenuItem data-test-id="menu-link-account" onClick={handleOpenAccount} sx={MenuItemSx}>
              {t('Account')}
            </MenuItem>
            {userTiles.length > 0 ? (
              <MenuItem
                data-test-id="menu-link-saved-regions"
                onClick={handleOpenSavedRegions}
                sx={{
                  ...MenuItemSx,
                  ...(hasNewSavedRegions && {
                    color: 'var(--green)',
                    fontWeight: 600
                  })
                }}
              >
                {t('Saved Regions')}
              </MenuItem>
            ) : null}
            <MenuItem
              component="a"
              target="_blank"
              href="https://cyclops.ai"
              data-test-id="menu-link-tou"
              onClick={handleCloseMenu}
              sx={MenuItemSx}
            >
              {t('About')}
            </MenuItem>
            <MenuItem component="a" target="_blank" href="/terms-of-use" sx={MenuItemSx}>
              {t('Terms of Use')}
            </MenuItem>
            <MenuItem
              component="a"
              target="_blank"
              href="/privacy-policy"
              data-test-id="menu-link-pp"
              onClick={handleCloseMenu}
              sx={MenuItemSx}
            >
              {t('Privacy Policy')}
            </MenuItem>
          </div>
        ) : (
          <MenuItem
            data-test-id="menu-link-back-to-app"
            onClick={handleLinkClick}
            sx={MenuItemSx}
            href={'/'}
            component={'a'}
          >
            {t('Back to app')}
          </MenuItem>
        )}
        <MenuItem data-test-id="menu-link-logout" onClick={handleLogout} sx={MenuItemSx}>
          {t('Log out')}
        </MenuItem>

        {user.role === 'free' ? (
          <Button variant={'purple'} fullWidth onClick={() => dispatch(openModal('upgradeAccount'))}>
            {t('Upgrade to Pro')}
          </Button>
        ) : null}
      </DropdownMenu>
    </>
  ) : null;
};

export default Menu;
