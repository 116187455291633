import { useMemo } from 'react';
import Spacer from '../../../Common/Spacer';
import { PropertySection } from '../components/PropertySection';
import { usePublicProjectData } from '../hooks/usePublicProjectData';

export const Project = () => {
  const { properties } = usePublicProjectData();

  const excludedKeys = useMemo(
    () => [
      'description',
      'buffers',
      'documents',
      'issuances',
      'center',
      'originalGeometry',
      'pdfUrl',
      'projectId',
      'resourceName',
      'resourceStatus',
      'projectType',
      'location',
      'name'
    ],
    []
  );

  const filteredProperties = useMemo(
    () =>
      Object.entries(properties)
        .filter(([key, value]) => !excludedKeys.includes(key) && !!value)
        .map(([key, value]) => [key, String(value)]),
    [properties, excludedKeys]
  );

  return (
    <>
      <PropertySection title="description" value={properties.description || ''} withNoLimit />
      <Spacer size="20px 0 0" />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap' }}>
        {filteredProperties.map(([key, value], index) => (
          <PropertySection key={`Property-${index}`} title={key} value={value} style={{ width: '125px' }} />
        ))}
      </div>
    </>
  );
};
