import { useCallback, useEffect, useRef } from 'react';
import { theme } from '../../../../theme/Theme';

interface Props {
  lineString: GeoJSON.Feature<GeoJSON.LineString>;
  width?: number;
  height?: number;
  strokeColor?: string;
  dataTestId?: string;
  lineWidth?: number;
}

interface BoundingBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

interface CanvasTransformParams {
  ratio: number;
  xOffset: number;
  yOffset: number;
}

const LineCanvas = ({
  lineString,
  width = 400,
  height = 400,
  strokeColor = theme.colors.purple,
  dataTestId,
  lineWidth = 1
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  /**
   * Calculates the bounding box of a LineString's coordinates
   *
   * @param {GeoJSON.Position[]} coordinates - The coordinates of the LineString
   * @returns {{ minX: number, minY: number, maxX: number, maxY: number }} The bounding box of the coordinates
   */
  const getBoundingBox = (coordinates: GeoJSON.Position[]): BoundingBox => {
    return coordinates.reduce(
      (box, coord) => {
        const [x, y] = coord;
        box.minX = Math.min(box.minX, x);
        box.maxX = Math.max(box.maxX, x);
        box.minY = Math.min(box.minY, y);
        box.maxY = Math.max(box.maxY, y);
        return box;
      },
      { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
    );
  };

  /**
   * Calculates the canvas transform parameters to fit a LineString into a canvas
   *
   * @param {{ minX: number, minY: number, maxX: number, maxY: number }} boundingBox - The bounding box of the LineString's coordinates
   * @param {number} canvasWidth - The width of the canvas
   * @param {number} canvasHeight - The height of the canvas
   * @returns {{ ratio: number, xOffset: number, yOffset: number }} The canvas transform parameters
   */
  const getCanvasTransformParams = useCallback(
    (boundingBox: BoundingBox): CanvasTransformParams => {
      const xRatio = width / (boundingBox.maxX - boundingBox.minX);
      const yRatio = height / (boundingBox.maxY - boundingBox.minY);
      const ratio = Math.min(xRatio, yRatio);
      const xOffset = -boundingBox.minX * ratio;
      const yOffset = -boundingBox.minY * ratio;

      return { ratio, xOffset, yOffset };
    },
    [width, height]
  );

  /**
   * Draws a LineString on a canvas
   *
   * @param {CanvasRenderingContext2D} ctx - The 2D rendering context of the canvas
   * @param {GeoJSON.Position[]} coordinates - The coordinates of the LineString
   * @param {number} ratio - The ratio used to scale the LineString to fit the canvas
   * @param {number} xOffset - The x-axis offset used to position the LineString in the canvas
   * @param {number} yOffset - The y-axis offset used to position the LineString in the canvas
   */
  const drawLineString = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      coordinates: GeoJSON.Position[],
      ratio: number,
      xOffset: number,
      yOffset: number
    ) => {
      ctx.beginPath();
      ctx.moveTo(coordinates[0][0] * ratio + xOffset, height - (coordinates[0][1] * ratio + yOffset));

      for (let i = 1; i < coordinates.length; i++) {
        const [x, y] = coordinates[i];
        ctx.lineTo(x * ratio + xOffset, height - (y * ratio + yOffset));
      }

      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    },
    [height, lineWidth, strokeColor]
  );

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        const coordinates = lineString.geometry.coordinates;
        const boundingBox = getBoundingBox(coordinates);
        const { ratio, xOffset, yOffset } = getCanvasTransformParams(boundingBox);
        drawLineString(ctx, coordinates, ratio, xOffset, yOffset);
      }
    }
  }, [lineString, width, height, getCanvasTransformParams, drawLineString]);

  return <canvas data-test-id={dataTestId} ref={canvasRef} width={width} height={height} />;
};

export default LineCanvas;
