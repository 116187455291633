import { TooltipProps } from 'recharts';
import { Props } from 'recharts/types/component/DefaultLegendContent';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import styled from 'styled-components';
import { ForestDataType } from '../../context/Polygon/types';
import { AreaUnitType } from '../../types/Geo';
import { ColorVariant } from '../../types/Theme';
import { IUser } from '../../types/User';

export const CHART_COMMON_LABEL_PROPS = {
  angle: -90,
  position: 'left',
  style: {
    fontSize: '12px',
    textAnchor: 'middle'
  }
};

export const CHART_COMMON_PROPS = {
  width: 560,
  height: 333,
  margin: {
    top: 0,
    left: 10,
    right: 0,
    bottom: 0
  },
  barGap: 20
};

export const CHART_AXIS_COMMON_PROPS = {
  tick: {
    fontSize: '12px',
    fontWeight: 700,
    fill: '#ffffff'
  },
  tickLine: false
};

export const CHART_X_AXIS_COMMON_PROPS = {
  dataKey: 'name',
  tick: {
    fontSize: '12px',
    fontWeight: 700,
    fill: '#ffffff'
  },
  tickLine: false
};

export const CHART_LINE_COMMON_PROPS = {
  dot: false,
  strokeWidth: 2
};

export const TooltipText = styled.p<{ color: ColorVariant }>`
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  border: 0;
`;

export const LegendWrapper = styled.ul`
  display: flex;
  list-style: none;
  height: 48px;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const LegendItem = styled.li`
  margin-right: 30px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin: 0;
  }
`;

export const LegendCircle = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  margin-right: 8px;
  border-radius: 50%;
`;

export const LegendText = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.grey};
`;

// custom legend component
export const renderLegend = (props: Props) => {
  const legendData = props.payload;

  return (
    <LegendWrapper>
      {legendData?.map((legendItem) => (
        <LegendItem key={`Legend-item-${String(legendItem.value)}`}>
          {legendItem.color && <LegendCircle color={legendItem.color} />}
          <LegendText>{legendItem.value}</LegendText>
        </LegendItem>
      ))}
    </LegendWrapper>
  );
};

export const renderTooltip = ({
  active,
  payload,
  user,
  forestDataType
}: TooltipProps<ValueType, NameType> & {
  user?: IUser | null;
  forestDataType: ForestDataType;
}) => {
  return active && payload ? (
    <TooltipWrapper>
      {forestDataType !== ForestDataType.PERCENTAGE ? (
        <>
          <TooltipText color="green2">
            {payload[0].value
              ? `${new Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  maximumFractionDigits: 5
                }).format(+payload[0].value)} ${user?.settings.unit?.replace('sq-', '') || 'km'}`
              : `0 ${user?.settings.unit?.replace('sq-', '') || 'km'}`}
            {user?.settings.unit === AreaUnitType.squareKilometre || user?.settings.unit === AreaUnitType.squareMile ? (
              <sup>2</sup>
            ) : null}
          </TooltipText>
        </>
      ) : (
        <>
          <TooltipText color="green2">
            {payload[0].value
              ? `${new Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  maximumFractionDigits: 5
                }).format(+payload[0].value)}%`
              : '0%'}
          </TooltipText>
        </>
      )}
      {payload && payload[0].payload ? (
        // disabled as the payload is of type 'any' from recharts
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        <TooltipText color="black">{payload[0].payload.name}</TooltipText>
      ) : null}
    </TooltipWrapper>
  ) : null;
};
