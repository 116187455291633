import { useCallback } from 'react';

export interface BoundingBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface CanvasTransformParams {
  ratio: number;
  xOffset: number;
  yOffset: number;
}

export interface BaseCanvasProps {
  width?: number;
  height?: number;
  dataTestId?: string;
}

/**
 * Calculates the canvas transform parameters to fit geometry into a canvas
 *
 * @param {{ minX: number, minY: number, maxX: number, maxY: number }} boundingBox - The bounding box of the geometry
 * @param {number} width - The width of the canvas
 * @param {number} height - The height of the canvas
 * @returns {{ ratio: number, xOffset: number, yOffset: number }} The canvas transform parameters
 */
export const getCanvasTransformParams = (
  boundingBox: BoundingBox,
  width: number,
  height: number
): CanvasTransformParams => {
  const xRatio = width / (boundingBox.maxX - boundingBox.minX);
  const yRatio = height / (boundingBox.maxY - boundingBox.minY);
  const ratio = Math.min(xRatio, yRatio);
  const xOffset = -boundingBox.minX * ratio;
  const yOffset = -boundingBox.minY * ratio;

  return { ratio, xOffset, yOffset };
};

/**
 * Custom hook for handling canvas rendering logic
 */
export const useCanvasRendering = (
  width: number,
  height: number,
  drawFunction: (ctx: CanvasRenderingContext2D, ratio: number, xOffset: number, yOffset: number) => void
) => {
  const renderCanvas = useCallback(
    (canvas: HTMLCanvasElement, boundingBox: BoundingBox) => {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const { ratio, xOffset, yOffset } = getCanvasTransformParams(boundingBox, width, height);
      drawFunction(ctx, ratio, xOffset, yOffset);
    },
    [width, height, drawFunction]
  );

  return { renderCanvas };
};
