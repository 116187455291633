import { useCallback, useEffect, useRef } from 'react';
import { theme } from '../../../../theme/Theme';
import { BaseCanvasProps, BoundingBox, useCanvasRendering } from '../common';

interface Props extends BaseCanvasProps {
  point: GeoJSON.Feature<GeoJSON.Point>;
  fillColor?: string;
  strokeColor?: string;
  radius?: number;
}

const PointCanvas = ({
  point,
  width = 400,
  height = 400,
  fillColor = theme.colors.purple,
  strokeColor = theme.colors.purple,
  dataTestId,
  radius = 5
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  /**
   * Calculates the bounding box of a Point's coordinates
   */
  const getBoundingBox = useCallback(
    (coordinates: GeoJSON.Position): BoundingBox => {
      const [x, y] = coordinates;
      return {
        minX: x - radius,
        minY: y - radius,
        maxX: x + radius,
        maxY: y + radius
      };
    },
    [radius]
  );

  /**
   * Draws a Point on a canvas
   */
  const drawPoint = useCallback(
    (ctx: CanvasRenderingContext2D, ratio: number, xOffset: number, yOffset: number) => {
      const [x, y] = point.geometry.coordinates;
      const canvasX = x * ratio + xOffset;
      const canvasY = height - (y * ratio + yOffset);

      ctx.beginPath();
      ctx.arc(canvasX, canvasY, radius, 0, 2 * Math.PI);
      ctx.fillStyle = fillColor;
      ctx.strokeStyle = strokeColor;
      ctx.fill();
      ctx.stroke();
    },
    [height, radius, fillColor, strokeColor, point.geometry.coordinates]
  );

  const { renderCanvas } = useCanvasRendering(width, height, drawPoint);

  useEffect(() => {
    if (canvasRef.current) {
      const coordinates = point.geometry.coordinates;
      const boundingBox = getBoundingBox(coordinates);
      renderCanvas(canvasRef.current, boundingBox);
    }
  }, [point, getBoundingBox, renderCanvas]);

  return <canvas data-test-id={dataTestId} ref={canvasRef} width={width} height={height} />;
};

export default PointCanvas;
