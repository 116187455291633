import { useCallback, useEffect, useRef } from 'react';
import { theme } from '../../../../theme/Theme';
import { BaseCanvasProps, BoundingBox, useCanvasRendering } from '../common';

interface Props extends BaseCanvasProps {
  multiPoint: GeoJSON.Feature<GeoJSON.MultiPoint>;
  fillColor?: string;
  strokeColor?: string;
  radius?: number;
}

const MultiPointCanvas = ({
  multiPoint,
  width = 400,
  height = 400,
  fillColor = theme.colors.purple,
  strokeColor = theme.colors.purple,
  dataTestId,
  radius = 5
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  /**
   * Calculates the bounding box of a MultiPoint's coordinates
   */
  const getBoundingBox = useCallback(
    (coordinates: GeoJSON.Position[]): BoundingBox => {
      return coordinates.reduce(
        (box, coord) => {
          const [x, y] = coord;
          box.minX = Math.min(box.minX, x - radius);
          box.maxX = Math.max(box.maxX, x + radius);
          box.minY = Math.min(box.minY, y - radius);
          box.maxY = Math.max(box.maxY, y + radius);
          return box;
        },
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
      );
    },
    [radius]
  );

  /**
   * Draws a MultiPoint on a canvas
   */
  const drawMultiPoint = useCallback(
    (ctx: CanvasRenderingContext2D, ratio: number, xOffset: number, yOffset: number) => {
      ctx.fillStyle = fillColor;
      ctx.strokeStyle = strokeColor;

      multiPoint.geometry.coordinates.forEach(([x, y]) => {
        const canvasX = x * ratio + xOffset;
        const canvasY = height - (y * ratio + yOffset);

        ctx.beginPath();
        ctx.arc(canvasX, canvasY, radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
      });
    },
    [height, radius, fillColor, strokeColor, multiPoint.geometry.coordinates]
  );

  const { renderCanvas } = useCanvasRendering(width, height, drawMultiPoint);

  useEffect(() => {
    if (canvasRef.current) {
      const coordinates = multiPoint.geometry.coordinates;
      const boundingBox = getBoundingBox(coordinates);
      renderCanvas(canvasRef.current, boundingBox);
    }
  }, [multiPoint, getBoundingBox, renderCanvas]);

  return <canvas data-test-id={dataTestId} ref={canvasRef} width={width} height={height} />;
};

export default MultiPointCanvas;
