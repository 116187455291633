import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ElementsWrapper = styled.div<{ isColumnMode?: boolean }>`
  display: flex;
  flex-direction: ${({ isColumnMode }) => (isColumnMode ? 'column' : 'row')};
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blackI};
  transition: ${({ theme }) => theme.transition};
  border: 1px solid transparent;
  flex-wrap: wrap;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.green};
  }
`;

export const Sections = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const Section = styled.div<{ withNoLimit?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: ${({ withNoLimit }) => (withNoLimit ? 'none' : '200px')};

  span {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
  }

  p {
    font-size: 15px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    max-width: ${({ withNoLimit }) => (withNoLimit ? '100%' : '150px')};
    overflow-wrap: ${({ withNoLimit }) => (withNoLimit ? 'normal' : 'anywhere')};
    text-wrap: ${({ withNoLimit }) => (withNoLimit ? 'initial' : 'wrap')};
  }
`;
