import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { setPublicProjectTab } from '../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IPublicProjectMetadata } from '../../../types/API/Region';
import { Navigation, TextSection, TextSections } from '../../DataBoard/Header/style';
import { PublicText, PublicTextSections, PublicTextTitle } from '../style';

const PublicProjectsHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { publicProjectTab } = useAppSelector((state) => state.uiState);

  const handleChange = useCallback(
    (_event: SyntheticEvent, newValue: string) => {
      dispatch(setPublicProjectTab(newValue));
    },
    [dispatch]
  );
  const publicProperties = useMemo(() => {
    return selectedPolygon?.properties as unknown as IPublicProjectMetadata;
  }, [selectedPolygon]);

  return (
    <>
      <PublicTextSections>
        <TextSection>
          <PublicTextTitle data-test-id="public-projects-header-section-title">{t('Name')}</PublicTextTitle>
          <PublicText data-test-id="public-projects-header-section-text">{selectedPolygon?.name}</PublicText>
        </TextSection>
        <TextSections>
          {publicProperties?.projectId ? (
            <TextSection>
              <PublicTextTitle data-test-id="public-projects-header-section-title">{t('ID')}</PublicTextTitle>
              <PublicText data-test-id="public-projects-header-section-text">{publicProperties.projectId}</PublicText>
            </TextSection>
          ) : null}
          {publicProperties.projectType ? (
            <TextSection>
              <PublicTextTitle data-test-id="public-projects-header-section-title">{t('Type')}</PublicTextTitle>
              <PublicText data-test-id="public-projects-header-section-text">{publicProperties.projectType}</PublicText>
            </TextSection>
          ) : null}
          {publicProperties.resourceStatus ? (
            <TextSection>
              <PublicTextTitle data-test-id="public-projects-header-section-title">{t('Status')}</PublicTextTitle>
              <PublicText data-test-id="public-projects-header-section-text">
                {publicProperties.resourceStatus}
              </PublicText>
            </TextSection>
          ) : null}
        </TextSections>
      </PublicTextSections>
      <Navigation>
        <TabContext value={publicProjectTab}>
          <TabList onChange={handleChange}>
            <Tab value="0" label={t('Project')} data-test-id="public-projects-header-tab-button-project" />
            {publicProperties?.issuances && publicProperties?.issuances.length > 0 ? (
              <Tab value="1" label={t('Issuances')} data-test-id="public-projects-header-tab-button-issuances" />
            ) : null}
            {publicProperties?.buffers && publicProperties?.buffers.length > 0 ? (
              <Tab value="2" label={t('Buffers')} data-test-id="public-projects-header-tab-button-buffers" />
            ) : null}
            {publicProperties?.documents && publicProperties?.documents.length > 0 ? (
              <Tab value="3" label={t('Documents')} data-test-id="public-projects-header-tab-button-documents" />
            ) : null}
          </TabList>
        </TabContext>
      </Navigation>
    </>
  );
};

export default PublicProjectsHeader;
