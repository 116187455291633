import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';
import { Title } from '../../SavedRegions/Header/style';
import { Wrapper } from './style';

const Header = () => {
  const { t } = useTranslation();
  const { publicProjects } = useAppSelector((state) => state.publicProjectsState);
  return (
    <Wrapper>
      <Title>{t('Public Projects')}</Title>
      <span>
        Total projects: <strong>{publicProjects.length}</strong>
      </span>
    </Wrapper>
  );
};

export default Header;
