import styled from 'styled-components';

export const RegionListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const RegionItem = styled.li`
  display: grid;
  grid-template-columns: 112px 1fr;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blackII};
  position: relative;
`;

export const RegionItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const RegionItemName = styled.h3`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.silver};
  font-weight: 700;
  margin-bottom: 5px;
`;

export const RegionItemProperties = styled.div`
  display: flex;
  gap: 30px;
  align-items: baseline;
`;

export const RegionItemProperty = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 500;
`;

export const RegionToggle = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  border: 0;
`;

export const RegionToggleIconWrapper = styled.div<{ selected: boolean }>`
  margin-top: ${({ selected }) => (selected ? '-3px' : 0)};
  margin-right: ${({ selected }) => (selected ? '-3px' : 0)};
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownToggle = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  transition: ${({ theme }) => theme.transition};
  width: 24px;
  height: 14px;
  padding: 0;

  &:hover {
    opacity: 0.6;
  }
`;

export const DropdownMenu = styled.div<{
  active?: boolean;
  position: 'left' | 'right';
}>`
  border-radius: 10px;
  position: absolute;
  top: 25px;
  left: ${({ position }) => (position === 'left' ? '25px' : 'unset')};
  right: ${({ position }) => (position === 'right' ? '45px' : 'unset')};
  z-index: 2;
  padding: ${({ active }) => (active ? '30px' : '0')};
  background-color: rgba(9, 20, 29, 0.8);
  backdrop-filter: blur(5px);
  border: ${({ active, theme }) => (active ? `1px solid ${theme.colors.blue}` : '0')};
  display: flex;
  flex-direction: column;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(-20px)')};
  height: ${({ active }) => (active ? 'auto' : '0')};
  filter: ${({ active }) => (active ? 'none' : 'blur(6px)')};

  button {
    padding: 0;
    white-space: nowrap;
    margin-bottom: 10px;

    &:hover {
      opacity: 0.6;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
