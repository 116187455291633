import { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'usehooks-ts';
import {
  setCurrentPage,
  setFilteredPublicProjects,
  setTotalPages
} from '../../../redux/features/public-projects/public-projects-slice';
import { setPublicProjectSearchQuery } from '../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Icon from '../../Common/Icon';
import { SearchInput, SearchInputWrapper, Wrapper } from '../../MapSearch/style';

const PublicProjectsSearch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { publicProjectSearchQuery } = useAppSelector((state) => state.uiState);
  const debouncedSearchTerm = useDebounce(publicProjectSearchQuery, 500);
  const { publicProjects } = useAppSelector((state) => state.publicProjectsState);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setPublicProjectSearchQuery(e.target.value.toLowerCase()));
    },
    [dispatch]
  );

  useEffect(() => {
    if (debouncedSearchTerm) {
      const newFilteredProjects = publicProjects
        .filter(
          (project) =>
            project.name.toLowerCase().includes(debouncedSearchTerm) ||
            project.properties.country?.toLowerCase().includes(debouncedSearchTerm) ||
            project.properties.projectId?.toLowerCase().includes(debouncedSearchTerm)
        )
        .slice(0, 10);
      dispatch(setFilteredPublicProjects(newFilteredProjects));
      dispatch(setCurrentPage(1));
      dispatch(setTotalPages(Math.ceil(newFilteredProjects.length / 10)));
    } else {
      dispatch(setFilteredPublicProjects(publicProjects.slice(0, 10)));
      dispatch(setCurrentPage(1));
      dispatch(setTotalPages(Math.ceil(publicProjects.length / 10)));
    }
  }, [debouncedSearchTerm, dispatch, publicProjects]);

  return (
    <Wrapper data-test-id="public-projects-list-search">
      <SearchInputWrapper>
        <Icon color={'grey'} variant={'SEARCH'} size={14} />
        <SearchInput
          placeholder={String(t('Search by name, id or country...'))}
          onChange={handleOnChange}
          value={publicProjectSearchQuery}
        />
      </SearchInputWrapper>
    </Wrapper>
  );
};

export default PublicProjectsSearch;
