import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  dataBoardTab: string;
  publicProjectTab: string;
  showSliderImagesComponent: boolean;
  showSidebar: boolean;
  sidebarVisible: boolean;
  showNotifications: boolean;
  showSavedRegions: boolean;
  showPublicProjectsList: boolean;
  mapToolsExpanded: boolean;
  publicProjectSearchQuery: string;
}

const initialState: UIState = {
  dataBoardTab: '0',
  publicProjectTab: '0',
  showSliderImagesComponent: false,
  showSidebar: false,
  sidebarVisible: false,
  showNotifications: false,
  showSavedRegions: false,
  showPublicProjectsList: false,
  mapToolsExpanded: true,
  publicProjectSearchQuery: ''
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setDataBoardTab: (state, action: PayloadAction<string>) => {
      state.dataBoardTab = action.payload;
    },
    setShowSliderImagesComponent: (state, action: PayloadAction<boolean>) => {
      state.showSliderImagesComponent = action.payload;
      document.body.classList[action.payload ? 'add' : 'remove']('slider-images');
    },
    toggleSliderImagesComponent: (state) => {
      state.showSliderImagesComponent = !state.showSliderImagesComponent;
      document.body.classList.toggle('slider-images');
    },
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.showSidebar = action.payload;
      state.sidebarVisible = action.payload;
    },
    setSidebarVisible: (state, action: PayloadAction<boolean>) => {
      state.sidebarVisible = action.payload;
    },
    setShowNotifications: (state, action: PayloadAction<boolean>) => {
      state.showNotifications = action.payload;
    },
    setShowSavedRegions: (state, action: PayloadAction<boolean>) => {
      state.showSavedRegions = action.payload;
    },
    setShowPublicProjectsList: (state, action: PayloadAction<boolean>) => {
      state.showPublicProjectsList = action.payload;
    },
    setMapToolsExpanded: (state, action: PayloadAction<boolean>) => {
      state.mapToolsExpanded = action.payload;
    },
    setPublicProjectTab: (state, action: PayloadAction<string>) => {
      state.publicProjectTab = action.payload;
    },
    setPublicProjectSearchQuery: (state, action: PayloadAction<string>) => {
      state.publicProjectSearchQuery = action.payload;
    },
    resetUIState: (state) => {
      return {
        ...initialState,
        mapToolsExpanded: state.mapToolsExpanded
      };
    }
  }
});

export default uiSlice.reducer;
export const {
  setDataBoardTab,
  setShowSliderImagesComponent,
  setShowSidebar,
  setSidebarVisible,
  toggleSliderImagesComponent,
  resetUIState,
  setShowNotifications,
  setShowSavedRegions,
  setShowPublicProjectsList,
  setMapToolsExpanded,
  setPublicProjectTab,
  setPublicProjectSearchQuery
} = uiSlice.actions;
