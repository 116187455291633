import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, Legend, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { useAppSelector } from '../../../redux/hooks';
import { theme } from '../../../theme/Theme';
import { NameType, Props, ValueType } from '../../../types/Chart';
import { AreaWeightFullName } from '../../../types/Geo';
import { convertUnitToFullName, returnWeightByUnit } from '../../../utils/units';
import {
  CHART_AXIS_COMMON_PROPS,
  CHART_COMMON_LABEL_PROPS,
  CHART_COMMON_PROPS,
  CHART_X_AXIS_COMMON_PROPS,
  renderLegend,
  TooltipText,
  TooltipWrapper
} from '../common';

const BarChartComponent = ({ data, isFromSplitData, dataTestId }: Props) => {
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.userState);
  const { carbonDisplayPer } = useAppSelector((state) => state.regionState);

  /**
   * Function used for calculation of the position of the custom tooltip
   * It SHOWS the tooltip as well with the show param set to true
   * It also ADDS visual indicator over the toggled bar
   */
  const handleBarOnMouseMove = useCallback((eventData: MouseEvent) => {
    const element = document.querySelector(`.recharts-bar-rectangle path[x="${eventData.x}"]`);
    element?.setAttribute('style', 'stroke: rgb(255,255,255);');
  }, []);

  /**
   * Function used for calculation of the position of the custom tooltip
   * It HIDES the tooltip as well with the show param set to false
   * It also REMOVES visual indicator over the toggled bar
   */
  const handleBarOnMouseLeave = useCallback((eventData: MouseEvent) => {
    const element = document.querySelector(`.recharts-bar-rectangle path[x="${eventData.x}"]`);
    element?.setAttribute('style', 'stroke: none;');
  }, []);

  // Custom tooltip
  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <TooltipWrapper data-test-id={dataTestId && `${dataTestId}-tooltip`}>
          <TooltipText color="green2">
            {t('{{value, number}}', {
              value: payload?.[0]?.value ? +payload[0].value : 0,
              maximumFractionDigits: 5,
              notation: 'compact'
            })}
          </TooltipText>
          {payload && payload[0].payload ? (
            // 'any' comes from recharts
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            <TooltipText color="black">{payload[0].payload.name}</TooltipText>
          ) : null}
        </TooltipWrapper>
      );
    }

    return null;
  };

  return (
    <BarChart data={data} {...CHART_COMMON_PROPS}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
          <stop offset="0.43" stopColor={theme.colors.green} />
          theme
          <stop offset="1" stopColor={theme.colors.purple} />
        </linearGradient>
      </defs>
      {data && data.length > 0 ? (
        <XAxis
          id={dataTestId && `${dataTestId}-axis-x-tick`} // for E2E testing
          ticks={[data[0].name && data[0].name, data[data.length - 1].name && data[data.length - 1].name]}
          {...CHART_X_AXIS_COMMON_PROPS}
        />
      ) : null}
      <YAxis
        {...CHART_AXIS_COMMON_PROPS}
        id={dataTestId && `${dataTestId}-axis-y-tick`} // for E2E testing
        tickFormatter={(_value: number) =>
          new Intl.NumberFormat('en-US', {
            notation: 'compact',
            maximumFractionDigits: 5
          }).format(_value)
        }
        label={{
          ...CHART_COMMON_LABEL_PROPS,
          value:
            carbonDisplayPer === 'data'
              ? t('Megatonne')
              : `${AreaWeightFullName[returnWeightByUnit()]} / ${convertUnitToFullName(user?.settings.unit)}`
        }}
      />
      <Tooltip content={<CustomTooltip />} cursor={false} />
      <Bar
        onMouseMove={handleBarOnMouseMove}
        onMouseLeave={handleBarOnMouseLeave}
        dataKey="carbon"
        fill="url(#colorUv)"
        animationEasing="ease-out"
        id={dataTestId && `${dataTestId}-axis-x-tick`} // for E2E testing
        radius={[4, 4, 0, 0]}
        opacity={0.5}
      />
      {isFromSplitData ? (
        <Legend
          content={(props) => renderLegend(props)}
          id={dataTestId && `${dataTestId}-legend`} // for E2E testing
          payload={[
            {
              value: 'No data available',
              type: 'circle',
              color: theme.colors.red
            }
          ]}
        />
      ) : null}
    </BarChart>
  );
};

export default BarChartComponent;
