import { useMemo } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { IPublicProjectIssuance, IPublicProjectMetadata } from '../../../../types/API/Region';
import { ElementsWrapper, Section, Sections } from '../style';

export const Issuances = () => {
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const issuances = useMemo(
    () => (selectedPolygon?.properties as unknown as IPublicProjectMetadata)?.issuances,
    [selectedPolygon]
  );
  const parsedIssuances = useMemo(
    () => (typeof issuances === 'string' ? JSON.parse(issuances) : issuances) as IPublicProjectIssuance[],
    [issuances]
  );

  return (
    <Sections>
      {parsedIssuances?.map((issuance) => (
        <ElementsWrapper key={issuance.holdingIdentifier} isColumnMode={false}>
          {Object.entries(issuance)
            .filter(([, value]) => !!value)
            .sort(([key]) => (key === 'serialNumbers' ? -1 : 1))
            .map(([key, value], index) => (
              <Section key={`section-${issuance.holdingIdentifier}-${index}`} withNoLimit={index === 0}>
                <span>
                  {key
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, key[0].toUpperCase())
                    .trim()}
                </span>
                <p>{value}</p>
              </Section>
            ))}
        </ElementsWrapper>
      ))}
    </Sections>
  );
};
