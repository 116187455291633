import { useMemo } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { IPublicProjectMetadata } from '../../../../types/API/Region';

export const usePublicProjectData = () => {
  const { selectedPolygon } = useAppSelector((state) => state.regionState);

  const properties = useMemo(
    () => (selectedPolygon?.properties as unknown as IPublicProjectMetadata) || {},
    [selectedPolygon]
  );

  return {
    properties,
    selectedPolygon
  };
};
