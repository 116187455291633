import { useMemo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { ContentWrapper } from '../../DataBoard/style';
import { Buffers } from './Buffers';
import { Documents } from './Documents';
import { Issuances } from './Issuances';
import { Project } from './Project';
export const PublicProjectsContent = () => {
  const { publicProjectTab } = useAppSelector((state) => state.uiState);

  const renderChildren = useMemo(() => {
    switch (publicProjectTab) {
      case '0':
        return <Project />;
      case '1':
        return <Issuances />;
      case '2':
        return <Buffers />;
      case '3':
        return <Documents />;
      default:
        return null;
    }
  }, [publicProjectTab]);

  return <ContentWrapper>{renderChildren}</ContentWrapper>;
};
