import styled from 'styled-components';

export const SectionName = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
`;

export const DocumentsList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Document = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blackIII};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  transition: ${({ theme }) => theme.transition};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.green};
  }

  span {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    max-width: 125px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
