import { useCallback, useMemo } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import {
  PUBLIC_SOURCE_NAME,
  PUBLIC_TILE_LAYERS,
  SOURCE_NAME,
  SPLIT_DRAWN_SOURCE_NAME_FILELD,
  SPLIT_DRAWN_SOURCE_NAME_UNFILLED,
  SPLIT_TILE_LAYERS_FILLED,
  SPLIT_TILE_LAYERS_UNFILLED,
  TILE_LAYERS,
  USER_SOURCE_NAME,
  USER_TILE_LAYERS
} from '../../constants/map';
import { useAppSelector } from '../../redux/hooks';
import {
  DrawInsideTileFillPaint,
  DrawInsideTileLinePaint,
  DrawOutsideTileFillPaint,
  DrawOutsideTileLinePaint,
  FillPaint,
  LinePaint,
  PublicProjectsFillPaint,
  PublicProjectsLinePaint,
  UserFillPaint,
  UserLinePaint
} from './style';
import { prepareRegionsData } from './utils';

export const useMapSources = () => {
  const { coveredArea, userTiles, deforestationRasterImageUrl, deforestationBboxCoordinates } = useAppSelector(
    (state) => state.regionState
  );
  const { splitDrawnPolygon } = useAppSelector((state) => state.drawState);
  const { sources, dataLayer } = useAppSelector((state) => state.mapState);
  const { publicProjects } = useAppSelector((state) => state.publicProjectsState);
  const { mapRoot } = useMap();

  const beforeIdOutline = useMemo(() => {
    // Order is as it goes
    // Saved projects -> Public Projects -> Predefined tiles
    if (userTiles?.length && mapRoot?.getLayer(USER_TILE_LAYERS.OUTLINE)) {
      return mapRoot.getLayer(PUBLIC_TILE_LAYERS.OUTLINE) ? PUBLIC_TILE_LAYERS.OUTLINE : TILE_LAYERS.OUTLINE;
    } else if (publicProjects.length > 0 && mapRoot?.getLayer(PUBLIC_TILE_LAYERS.OUTLINE)) {
      return mapRoot.getLayer(TILE_LAYERS.OUTLINE) ? TILE_LAYERS.OUTLINE : undefined;
    }
    return undefined;
  }, [publicProjects.length, mapRoot, userTiles?.length]);

  const beforeIdFill = useMemo(() => {
    // Order is as it goes (bottom to top):
    // Predefined tiles -> Public Projects -> User tiles
    if (mapRoot?.getLayer(TILE_LAYERS.FILLS)) {
      return TILE_LAYERS.FILLS;
    } else if (mapRoot?.getLayer(PUBLIC_TILE_LAYERS.FILLS)) {
      return PUBLIC_TILE_LAYERS.FILLS;
    }
    return undefined;
  }, [mapRoot]);

  /**
   * Render of React-Map-GL Source for the BiomassAPI predefined tiles
   */
  const renderCoveredArea = useCallback(() => {
    if (coveredArea && coveredArea.length > 0) {
      const tilesData = prepareRegionsData(coveredArea);

      return (
        <Source id={SOURCE_NAME} type="geojson" data={tilesData} generateId>
          <Layer
            type="line"
            id={TILE_LAYERS.OUTLINE}
            source={SOURCE_NAME}
            paint={LinePaint}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('default') ? 'visible' : 'none'
            }}
          />
          <Layer
            type="fill"
            id={TILE_LAYERS.FILLS}
            source={SOURCE_NAME}
            paint={FillPaint}
            layout={{
              visibility: sources.includes('default') ? 'visible' : 'none'
            }}
          />
        </Source>
      );
    }
  }, [coveredArea, sources]);

  /**
   * Render of React-Map-GL Source for the user saved regions
   */
  const renderUserRegions = useCallback(() => {
    // first check if predefined tiles are already rendered on the map
    if (userTiles?.length > 0 && coveredArea.length > 0) {
      const tilesData = prepareRegionsData(userTiles);
      // get feature on the map by the featureState
      return (
        <Source id={USER_SOURCE_NAME} type="geojson" data={tilesData} generateId>
          <Layer
            type="line"
            id={USER_TILE_LAYERS.OUTLINE}
            source={USER_SOURCE_NAME}
            paint={UserLinePaint}
            beforeId={beforeIdFill}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('user') ? 'visible' : 'none'
            }}
          />
          <Layer
            type="fill"
            id={USER_TILE_LAYERS.FILLS}
            source={USER_SOURCE_NAME}
            beforeId={beforeIdOutline}
            paint={{
              ...UserFillPaint,
              'fill-opacity': ['case', ['==', ['feature-state', 'clicked'], true], dataLayer === null ? 1 : 0, 1]
            }}
            layout={{
              visibility: sources.includes('user') ? 'visible' : 'none'
            }}
          />
        </Source>
      );
    }
  }, [beforeIdFill, beforeIdOutline, coveredArea.length, dataLayer, sources, userTiles]);

  /**
   * Render of React-Map-GL Source for the currently drawn polygon
   * This part of data is rendered on existing tile
   */
  const renderSplitDrawnPolygonOnTile = useCallback(() => {
    if (splitDrawnPolygon?.drawnDataOnTile) {
      return (
        <Source
          id={SPLIT_DRAWN_SOURCE_NAME_UNFILLED}
          type="geojson"
          data={splitDrawnPolygon.drawnDataOnTile}
          generateId
        >
          <Layer
            type="line"
            id={SPLIT_TILE_LAYERS_UNFILLED.OUTLINE}
            source={SPLIT_DRAWN_SOURCE_NAME_UNFILLED}
            paint={DrawInsideTileLinePaint}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('hidden') ? 'none' : 'visible'
            }}
          />
          <Layer
            type="fill"
            id={SPLIT_TILE_LAYERS_UNFILLED.FILLS}
            source={SPLIT_DRAWN_SOURCE_NAME_UNFILLED}
            paint={{
              ...DrawInsideTileFillPaint,
              'fill-opacity': dataLayer === null ? 1 : 0
            }}
            layout={{
              visibility: sources.includes('hidden') ? 'none' : 'visible'
            }}
          />
        </Source>
      );
    }
  }, [dataLayer, sources, splitDrawnPolygon?.drawnDataOnTile]);

  /**
   * Render of React-Map-GL Source for the currently drawn polygon
   * This part of data is rendered outside existing tile
   */
  const renderSplitDrawnPolygonOutsideTile = useCallback(() => {
    if (splitDrawnPolygon?.drawnDataOutsideTile) {
      return (
        <Source
          id={SPLIT_DRAWN_SOURCE_NAME_FILELD}
          type="geojson"
          data={splitDrawnPolygon.drawnDataOutsideTile}
          generateId
        >
          <Layer
            type="line"
            id={SPLIT_TILE_LAYERS_FILLED.OUTLINE}
            source={SPLIT_DRAWN_SOURCE_NAME_FILELD}
            paint={DrawOutsideTileLinePaint}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('hidden') ? 'none' : 'visible'
            }}
          />
          <Layer
            type="fill"
            id={SPLIT_TILE_LAYERS_FILLED.FILLS}
            source={SPLIT_DRAWN_SOURCE_NAME_FILELD}
            paint={DrawOutsideTileFillPaint}
            layout={{
              visibility: sources.includes('hidden') ? 'none' : 'visible'
            }}
          />
        </Source>
      );
    }
  }, [sources, splitDrawnPolygon?.drawnDataOutsideTile]);

  /**
   * Render of Public Projects
   */
  const renderPublicProjects = useCallback(() => {
    if (publicProjects.length > 0 && coveredArea.length > 0) {
      return (
        <Source id={PUBLIC_SOURCE_NAME} type="geojson" data={prepareRegionsData(publicProjects)} generateId>
          <Layer
            type="line"
            id={PUBLIC_TILE_LAYERS.OUTLINE}
            source={PUBLIC_SOURCE_NAME}
            paint={PublicProjectsLinePaint}
            beforeId={beforeIdOutline}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('public') ? 'visible' : 'none'
            }}
          />
          <Layer
            type="fill"
            id={PUBLIC_TILE_LAYERS.FILLS}
            source={PUBLIC_SOURCE_NAME}
            paint={{
              ...PublicProjectsFillPaint,
              'fill-opacity': ['case', ['==', ['feature-state', 'clicked'], true], dataLayer === null ? 1 : 0, 1]
            }}
            beforeId={beforeIdFill}
            layout={{
              visibility: sources.includes('public') ? 'visible' : 'none'
            }}
          />
        </Source>
      );
    }
  }, [beforeIdFill, beforeIdOutline, coveredArea.length, dataLayer, publicProjects, sources]);

  const renderRasterImageSource = useCallback(() => {
    if (deforestationRasterImageUrl && deforestationBboxCoordinates) {
      return (
        <Source
          id="raster-image-source"
          type="image"
          url={deforestationRasterImageUrl}
          coordinates={[
            deforestationBboxCoordinates[3],
            deforestationBboxCoordinates[2],
            deforestationBboxCoordinates[1],
            deforestationBboxCoordinates[0]
          ]}
        >
          <Layer id="raster-image-layer" type="raster" source="raster-image-source" />
        </Source>
      );
    }
  }, [deforestationBboxCoordinates, deforestationRasterImageUrl]);

  return {
    renderCoveredArea,
    renderUserRegions,
    renderSplitDrawnPolygonOnTile,
    renderSplitDrawnPolygonOutsideTile,
    renderPublicProjects,
    renderRasterImageSource
  };
};
