import { useMemo } from 'react';
import { useGetSignedDownloadUrlMutation } from '../../../../redux/api/publicProjectsApi';
import { IPublicProjectDocument } from '../../../../types/API/Region';
import Icon from '../../../Common/Icon';
import { usePublicProjectData } from '../hooks/usePublicProjectData';
import { ElementsWrapper, Wrapper } from '../style';
import { Document, DocumentsList, SectionName } from './style';

export const Documents = () => {
  const [getSignedDownloadUrl, { isLoading }] = useGetSignedDownloadUrlMutation();
  const { properties } = usePublicProjectData();
  const documents = useMemo(
    () =>
      (typeof properties.documents === 'string'
        ? JSON.parse(properties.documents)
        : properties.documents) as IPublicProjectDocument[],
    [properties.documents]
  );

  const grouppedDocuments = useMemo(() => {
    return documents?.reduce((acc, document) => {
      if (document.documentType) {
        acc[document.documentType] = [...(acc[document.documentType] || []), document];
      }
      return acc;
    }, {} as Record<string, IPublicProjectDocument[]>);
  }, [documents]);

  const handleDownloadDocument = async (documentUrl: string) => {
    const data = await getSignedDownloadUrl(documentUrl).unwrap();
    window.open(data.signedUrl, '_blank');
  };

  const renderDocumentGroup = (docs: IPublicProjectDocument[], documentType: string) => (
    <ElementsWrapper key={documentType} isColumnMode={true}>
      <SectionName>{documentType}</SectionName>
      <DocumentsList>
        {docs.map((doc, index) => (
          <Document
            key={`${documentType}-${doc.documentName || index}`}
            disabled={isLoading}
            onClick={async () => {
              if (doc.uri) {
                await handleDownloadDocument(doc.uri);
              }
            }}
          >
            <span>{doc.documentName}</span>
            <Icon variant="DOWNLOAD" color="white" size={16} />
          </Document>
        ))}
      </DocumentsList>
    </ElementsWrapper>
  );

  return (
    <Wrapper>
      {Object.entries(grouppedDocuments).map(([documentType, docs]) => renderDocumentGroup(docs, documentType))}
    </Wrapper>
  );
};
