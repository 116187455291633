import React from 'react';
import { Section } from '../style';

interface PropertySectionProps {
  title: string;
  value: string | number;
  style?: React.CSSProperties;
  withNoLimit?: boolean;
}

export const PropertySection: React.FC<PropertySectionProps> = ({ title, value, style, withNoLimit }) => {
  const formattedTitle = title
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, title[0].toUpperCase())
    .trim();

  return (
    <Section style={style} withNoLimit={withNoLimit}>
      <span>{formattedTitle}</span>
      <p>{value}</p>
    </Section>
  );
};
