import styled from 'styled-components';
import { Text, TextSections, TextTitle } from '../DataBoard/Header/style';

export const PublicTextTitle = styled(TextTitle)`
  font-size: 14px;
`;

export const PublicText = styled(Text)`
  font-size: 18px;
`;

export const PublicTextSections = styled(TextSections)`
  flex-direction: column;
  gap: 0;
`;
